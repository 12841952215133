import { FC, ReactNode, useEffect } from "react";
import styled from "styled-components";
import SimpleBarReact from "simplebar-react";
import { observer } from "mobx-react-lite";
import Header from "./Header";
import { mainFont } from "helpers/consts";
import { Preloader, ErrorToast, CookiesPopup } from "components";
// import { TelegramErrorModal } from "components/Modals";
import {
  GlobalStore,
  WalletStore,
  CurrenciesStore,
  StakesStore,
  UserStore,
} from "stores";

import type { WalletsListInterface, StakeInterface } from "helpers/types";

interface LayoutProps {
  children: ReactNode;
  isDarkTheme?: boolean;
}

const Layout: FC<LayoutProps> = ({ children, isDarkTheme }) => {
  const { loading } = GlobalStore;
  const { walletsList, setBalance } = WalletStore;
  const { prices } = CurrenciesStore;
  const { activeStakes, setStakesAmount } = StakesStore;
  const { isAuthenticated } = UserStore;

  useEffect(() => {
    if (walletsList && prices) {
      const totalBalance = walletsList.reduce(
        (acc: number, el: WalletsListInterface) => {
          if (prices?.[`${el?.currencyName}/USD`]?.close) {
            acc =
              acc +
              Number(el.balance) *
                Number(prices[`${el.currencyName}/USD`].close);
          }
          return acc;
        },
        0
      );
      setBalance(totalBalance);
    }
  }, [walletsList, prices, setBalance]);

  useEffect(() => {
    if (activeStakes && activeStakes.length > 0 && prices) {
      const totalStakesBalance = activeStakes.reduce(
        (acc: number, el: StakeInterface) => {
          if (prices?.[`${el.currencyName}/USD`]?.close) {
            acc =
              acc +
              Number(el.amount) *
                Number(prices[`${el.currencyName}/USD`].close);
          }
          return acc;
        },
        0
      );
      setStakesAmount(totalStakesBalance);
    }
  }, [activeStakes, prices, setStakesAmount]);

  return (
    <>
      {loading && <Preloader />}
      <ErrorToast />
      <SimpleBarReact style={{ width: "100%", height: "100dvh" }} tabIndex={-1}>
        <Header />
        <Main $isDarkTheme={isDarkTheme}>{children}</Main>
      </SimpleBarReact>
      <CookiesPopup isAuthenticated={isAuthenticated} />
      {/* <TelegramErrorModal /> */}
    </>
  );
};

interface MainProps {
  $isDarkTheme?: boolean;
}
const Main = styled.main<MainProps>`
  width: 100%;
  margin: 0 auto;
  max-width: ${({ $isDarkTheme }) => ($isDarkTheme ? `none` : "121.6rem")};
  padding: 0 0 4.8rem;

  @media (min-width: 36em) {
    padding: 0 1.2rem 4.8rem;
    max-width: ${({ $isDarkTheme }) => ($isDarkTheme ? `none` : "123rem")};
  }

  @media (min-width: 48em) {
    padding: 0 2rem 4.8rem;
    max-width: ${({ $isDarkTheme }) => ($isDarkTheme ? `none` : "125.6rem")};
  }

  * {
    font-family: ${mainFont};
  }

  .MuiTooltip-tooltip {
    font-size: 1rem;
  }
`;

export default observer(Layout);
